import { IoCall, IoMail, IoMap } from 'react-icons/io5';
import {
  ScHeader3,
  ScMaxWidthContainer,
  ScMaxWidthContainerWide,
} from './../components/styled-components';

import Image from '../components/image';
import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScBlackContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const content = `
<h2
    data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
class="fusion-responsive-typography-calculated"
>
Inhaltsverzeichnis
</h2>
<ul>
<li>
    <a href="#einleitung-ueberblick">Einleitung und Überblick</a>
</li>
<li>
    <a href="#anwendungsbereich">Anwendungsbereich</a>
</li>
<li>
    <a href="#rechtsgrundlagen">Rechtsgrundlagen</a>
</li>
<li>
    <a href="#kontaktdaten-verantwortliche">
    Kontaktdaten des Verantwortlichen
    </a>
</li>
<li>
    <a href="#speicherdauer">Speicherdauer</a>
</li>
<li>
    <a href="#rechte-dsgvo">Rechte laut Datenschutzgrundverordnung</a>
</li>
<li>
    <a href="#sicherheit-datenverarbeitung">
    Sicherheit der Datenverarbeitung
    </a>
</li>
<li>
    <a href="#kommunikation">Kommunikation</a>
</li>
<li>
    <a href="#webhosting">Webhosting</a>
</li>
<li>
    <a href="#cookies">Cookies</a>
</li>
<li>
    <a href="#web-analytics">Web Analytics</a>
</li>
<li>
    <a href="#social-media">Social Media</a>
</li>
<li>
    <a href="#facebook-datenschutzerklaerung">
    Facebook Datenschutzerklärung
    </a>
</li>
<li>
    <a href="#google-maps-datenschutzerklaerung">
    Google Maps Datenschutzerklärung
    </a>
</li>
<li>
    <a href="#google-fonts-lokal-datenschutzerklaerung">
    Google Fonts Lokal Datenschutzerklärung
    </a>
</li>
<li>
    <a href="#font-awesome-datenschutzerklaerung">
    Font Awesome Datenschutzerklärung
    </a>
</li>
</ul>
<h2
id="einleitung-ueberblick"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
Einleitung und Überblick
</h2>
<p>
Wir haben diese Datenschutzerklärung (Fassung 09.05.2021-121739092)
verfasst, um Ihnen gemäß der Vorgaben der 
<a
    class="adsimple-121739092"
    href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=121739092"
    target="_blank"
    rel="noopener"
>
    Datenschutz-Grundverordnung (EU) 2016/679
</a> 
und anwendbaren nationalen Gesetzen zu erklären, welche
personenbezogenen Daten (kurz Daten) wir als Verantwortliche – und
die von uns beauftragten Auftragsverarbeiter (z. B. Provider) –
verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen
Möglichkeiten Sie haben. Die verwendeten Begriffe sind
geschlechtsneutral zu verstehen.
<br />
<strong class="adsimple-121739092">Kurz gesagt:</strong> Wir
informieren Sie umfassend über Daten, die wir über Sie verarbeiten.
</p>
<p>
Datenschutzerklärungen klingen für gewöhnlich sehr technisch und
verwenden juristische Fachbegriffe. Diese Datenschutzerklärung soll
Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie
möglich beschreiben. Soweit es der Transparenz förderlich ist,
werden technische 
<strong class="adsimple-121739092">
    Begriffe leserfreundlich erklärt
</strong>
, <strong class="adsimple-121739092">Links</strong> zu
weiterführenden Informationen geboten und 
<strong class="adsimple-121739092">Grafiken</strong> zum Einsatz
gebracht. Wir informieren damit in klarer und einfacher Sprache,
dass wir im Rahmen unserer Geschäftstätigkeiten nur dann
personenbezogene Daten verarbeiten, wenn eine entsprechende
gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich,
wenn man nur möglichst knappe, unklare und juristisch-technische
Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn
es um Datenschutz geht. Ich hoffe Sie finden die folgenden
Erläuterungen interessant und informativ und vielleicht ist die eine
oder andere Information dabei, die Sie noch nicht kannten.
<br />
Wenn trotzdem Fragen offen bleiben, möchten wir Sie bitten, sich an
die unten bzw. im Impressum genannte verantwortliche Stelle zu
wenden, den vorhandenen Links zu folgen und sich weitere
Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden
Sie selbstverständlich auch im Impressum.
</p>
<h2
id="anwendungsbereich"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
Anwendungsbereich
</h2>
<p>
Diese Datenschutzerklärung gilt für alle, von uns im Unternehmen
verarbeiteten personenbezogenen Daten und für alle personenbezogenen
Daten, die von uns beauftragte Firmen (Auftragsverarbeiter)
verarbeiten. Mit personenbezogenen Daten meinen wir Informationen
wie zum Beispiel Name, E-Mail-Adresse und postalische Anschrift
einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür,
dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen
können, sei es online oder offline. Der Anwendungsbereich dieser
Datenschutzerklärung umfasst:
</p>
<ul class="adsimple-121739092">
<li class="adsimple-121739092">
    alle Onlineauftritte (Websites, Onlineshops), die wir betreiben
</li>
<li class="adsimple-121739092">
    Social Media Auftritte und E-Mail Kommunikation
</li>
<li class="adsimple-121739092">
    mobile Apps für Smartphones und andere Geräte
</li>
</ul>
<p>
<strong class="adsimple-121739092">Kurz gesagt:</strong> Alle
Bereiche wo personenbezogene Daten im Unternehmen strukturiert
verarbeitet werden.
</p>
<h2
id="rechtsgrundlagen"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
Rechtsgrundlagen
</h2>
<p>
In der folgenden Datenschutzerklärung geben wir Ihnen transparente
Informationen zu den rechtlichen Grundsätzen und Vorschriften, also
den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns
ermöglichen, personenbezogene Daten zu verarbeiten.
<br />
Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU)
2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April
2016. Diese Datenschutz-Grundverordnung der EU können Sie
selbstverständlich online auf EUR-Lex, dem Zugang zum EU-Recht,
unter 
<a
    class="adsimple-121739092"
    href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=121739092"
>
    https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679
</a> 
nachlesen.
</p>
<p>
Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden
Bedingungen zutrifft:
</p>
<ol>
<li class="adsimple-121739092">
    <strong class="adsimple-121739092">Einwilligung</strong> (Artikel
    6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben
    Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre
    die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.
</li>
<li class="adsimple-121739092">
    <strong class="adsimple-121739092">Vertrag</strong> (Artikel 6
    Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche
    Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten.
    Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschließen,
    benötigen wir vorab personenbezogene Informationen.
</li>
<li class="adsimple-121739092">
    <strong class="adsimple-121739092">
    Rechtliche Verpflichtung
    </strong> 
    (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen
    Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum
    Beispiel sind wir gesetzlich verpflichtet Rechnungen für die
    Buchhaltung aufzuheben. Diese enthalten in der Regel
    personenbezogene Daten.
</li>
<li class="adsimple-121739092">
    <strong class="adsimple-121739092">Berechtigte Interessen</strong> 
    (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter
    Interessen, die Ihre Grundrechte nicht einschränken, behalten wir
    uns die Verarbeitung personenbezogener Daten vor. Wir müssen zum
    Beispiel gewisse Daten verarbeiten um unsere Website sicher und
    wirtschaftlich effizient betreiben zu können, diese Verarbeitung
    ist somit ein berechtigtes Interesse.
</li>
</ol>
<p>
Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im
öffentlichen Interesse und Ausübung öffentlicher Gewalt sowie dem
Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht
auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein
sollte, wird diese an der entsprechenden Stelle ausgewiesen.
</p>
<p>
Zusätzlich zu der EU-Verordnung, gelten auch noch nationale Gesetze:
</p>
<ul class="adsimple-121739092">
<li class="adsimple-121739092">
    In <strong class="adsimple-121739092">Österreich</strong> ist dies
    das Bundesgesetz zum Schutz natürlicher Personen bei der
    Verarbeitung personenbezogener Daten (
    <strong class="adsimple-121739092">Datenschutzgesetz</strong>),
    kurz <strong class="adsimple-121739092">DSG</strong>.
</li>
<li class="adsimple-121739092">
    In <strong class="adsimple-121739092">Deutschland</strong> gilt
    das 
    <strong class="adsimple-121739092">
    Bundesdatenschutzgesetz
    </strong>
    , kurz<strong class="adsimple-121739092"> BDSG</strong>.
</li>
</ul>
<p>
Sofern weitere regionale oder nationale Gesetze zur Anwendung
kommen, informieren wir Sie in den folgenden Abschnitten darüber.
</p>
<h2
id="kontaktdaten-verantwortliche"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
Kontaktdaten des Verantwortlichen
</h2>
<p>
Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die
Kontaktdaten der verantwortlichen Person bzw. Stelle.
</p>
<p>
Sabine Pölzl
Franz-Josef-Straße 17
8200 Gleisdorf
</p>
<p>
<span class="adsimple-121739092" style="font-weight: 400">
    Vertretungsberechtigt: Stephan Pölzl
</span>
<span class="adsimple-121739092" style="font-weight: 400">
    <br />
</span>
</p>
<p>
E-Mail: 
<a class="adsimple-121739092" href="mailto:bea@lifebalance-coach.at">
    bea@lifebalance-coach.at
</a>
<br />
Telefon: 
<span class="adsimple-121739092" style="font-weight: 400">
    +43 676 377 09 27
</span>
<br />
Impressum:&nbsp;
<a
    class="adsimple-121739092"
    href="https://www.lifebalance-coach.at/imprint"
    target="_blank"
    rel="noopener"
>
    https://www.lifebalance-coach.at/imprint
</a>
</p>
<h2
id="speicherdauer"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
Speicherdauer
</h2>
<p>
Dass wir personenbezogene Daten nur so lange speichern, wie es für
die Bereitstellung unserer Dienstleistungen und Produkte unbedingt
notwendig ist, gilt als generelles Kriterium bei uns. Wenn es zum
Beispiel im Fall von Buchhaltung gesetzlich vorgeschrieben ist, kann
diese Speicherdauer auch überschritten werden. Das bedeutet, dass
wir personenbezogene Daten löschen sobald der Grund für die
Datenverarbeitung nicht mehr vorhanden ist. Sollten Sie die Löschung
Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung
widerrufen, werden die Daten so rasch wie möglich und soweit keine
Pflicht zur Speicherung besteht, gelöscht.
</p>
<p>
Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren
wir Sie weiter unten, sofern wir weitere Informationen dazu haben.
</p>
<h2
id="rechte-dsgvo"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
Rechte laut Datenschutzgrundverordnung
</h2>
<p>
Laut Artikel 13 DSGVO stehen Ihnen die folgenden Rechte zu, damit es
zu einer fairen und transparenten Verarbeitung von Daten kommt:
</p>
<ul class="adsimple-121739092">
<li class="adsimple-121739092">
    Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht ob wir Daten
    von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht
    darauf eine Kopie der Daten zu erhalten und die folgenden
    Informationen zu erfahren:
    <ul class="adsimple-121739092">
    <li class="adsimple-121739092">
        zu welchem Zweck wir die Verarbeitung durchführen;
    </li>
    <li class="adsimple-121739092">
        die Kategorien, also die Arten von Daten, die verarbeitet
        werden;
    </li>
    <li class="adsimple-121739092">
        wer diese Daten erhält und wenn die Daten an Drittländer
        übermittelt werden, wie die Sicherheit garantiert werden kann;
    </li>
    <li class="adsimple-121739092">
        wie lange die Daten gespeichert werden;
    </li>
    <li class="adsimple-121739092">
        das Bestehen des Rechts auf Berichtigung, Löschung oder
        Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen
        die Verarbeitung;
    </li>
    <li class="adsimple-121739092">
        dass Sie sich bei einer Aufsichtsbehörde beschweren können
        (Links zu diesen Behörden finden Sie weiter unten);
    </li>
    <li class="adsimple-121739092">
        die Herkunft der Daten wenn wir sie nicht bei Ihnen erhoben
        haben;
    </li>
    <li class="adsimple-121739092">
        ob Profiling durchgeführt wird, ob also Daten automatisch
        ausgewertet werden um zu einem persönlichen Profil von Ihnen
        zu gelangen.
    </li>
    </ul>
</li>
<li class="adsimple-121739092">
    Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der
    Daten, was bedeutet, dass wir Daten richtig stellen müssen, falls
    Sie Fehler finden.
</li>
<li class="adsimple-121739092">
    Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf
    Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung
    Ihrer Daten verlangen dürfen.
</li>
<li class="adsimple-121739092">
    Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der
    Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern
    aber nicht weiter verwenden dürfen.
</li>
<li class="adsimple-121739092">
    Sie haben laut Artikel 19 DSGVO das Recht auf
    Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage
    Ihre Daten in einem gängigen Format zur Verfügung stellen.
</li>
<li class="adsimple-121739092">
    Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches
    nach Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
    <ul class="adsimple-121739092">
    <li class="adsimple-121739092">
        Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e
        (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder
        Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert,
        können Sie gegen die Verarbeitung Widerspruch einlegen. Wir
        prüfen danach so rasch wie möglich ob wir diesem Widerspruch
        rechtlich nachkommen können.
    </li>
    <li class="adsimple-121739092">
        Werden Daten verwendet um Direktwerbung zu betreiben, können
        Sie jederzeit gegen diese Art der Datenverarbeitung
        widersprechen. Wir dürfen Ihre Daten danach nicht mehr für
        Direktmarketing verwenden.
    </li>
    <li class="adsimple-121739092">
        Werden Daten verwendet um Profiling zu betreiben, können Sie
        jederzeit gegen diese Art der Datenverarbeitung widersprechen.
        Wir dürfen Ihre Daten danach nicht mehr für Profiling
        verwenden.
    </li>
    </ul>
</li>
<li class="adsimple-121739092">
    Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht
    einer ausschließlich auf einer automatisierten Verarbeitung (zum
    Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.
</li>
</ul>
<p>
Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche
in sonst einer Weise verletzt worden sind, können Sie sich bei der
Aufsichtsbehörde beschweren. Diese ist für Österreich die
Datenschutzbehörde, deren Website Sie unter 
<a
    class="adsimple-121739092"
    href="https://www.dsb.gv.at/?tid=121739092"
>
    https://www.dsb.gv.at/
</a> 
finden und für Deutschland können Sie sich an die 
<a
    class="adsimple-121739092"
    href="https://www.bfdi.bund.de"
    target="_blank"
    rel="noopener"
>
    Bundesbeauftragte für den Datenschutz und die Informationsfreiheit
    (BfDI)
</a> 
wenden.
</p>
<p>
<strong class="adsimple-121739092">Kurz gesagt:</strong> Sie haben
Rechte – zögern Sie nicht, die oben gelistete verantwortliche Stelle
bei uns zu kontaktieren!
</p>
<h2
id="sicherheit-datenverarbeitung"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
Sicherheit der Datenverarbeitung
</h2>
<p>
Um personenbezogene Daten zu schützen, haben wir sowohl technische
als auch organisatorische Maßnahmen umgesetzt. Wo es uns möglich
ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten.
Dadurch machen wir es im Rahmen unserer Möglichkeiten so schwer wie
möglich, dass Dritte aus unseren Daten auf persönliche Informationen
schließen können.
</p>
<p>
Art. 25 DSGVO spricht hier von “Datenschutz durch Technikgestaltung
und durch datenschutzfreundliche Voreinstellungen” und meint damit,
dass man sowohl bei Software (z. B. Formularen) also auch Hardware
(z. B. Zugang zum Serverraum) immer an Sicherheit denkt und
entsprechende Maßnahmen setzt. Im Folgenden gehen wir, falls
erforderlich, noch auf konkrete Maßnahmen ein.
</p>
<h2
id="tls-verschluesselung-https"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
TLS-Verschlüsselung mit https
</h2>
<p>
TLS, Verschlüsselung und https klingen sehr technisch und sind es
auch. Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure
steht für „sicheres Hypertext-Übertragungsprotokoll“) um Daten
abhörsicher im Internet zu übertragen.
<br />
Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem
Browser zu unserem Webserver abgesichert ist – niemand kann
“mithören”.
</p>
<p>
Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und
erfüllen Datenschutz durch Technikgestaltung 
<a
    class="adsimple-121739092"
    href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=121739092"
    target="_blank"
    rel="noopener"
>
    Artikel 25 Absatz 1 DSGVO
</a>
). Durch den Einsatz von TLS (Transport Layer Security), einem
Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet
können wir den Schutz vertraulicher Daten sicherstellen.
<br />
Sie erkennen die Benutzung dieser Absicherung der Datenübertragung
am kleinen Schlosssymbol 
<img
    role="img"
    src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg"
    width="17"
    height="18"
/>
&nbsp;links oben im Browser links von der Internetadresse (z. B.
beispielseite.de) und der Verwendung des Schemas https (anstatt
http) als Teil unserer Internetadresse.
<br />
Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen
wir die Google Suche nach “Hypertext Transfer Protocol Secure wiki”
um gute Links zu weiterführenden Informationen zu erhalten.
</p>
<h2
id="kommunikation"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
Kommunikation
</h2>
<div style="margin-bottom: 10px;background: #fff;border: 1px solid #dfe1e5;border-radius: 8px;padding: 10px 10px 10px 15px">
<strong class="adsimple-121739092">
    Kommunikation Zusammenfassung
</strong>
<br />
👥 Betroffene: Alle, die mit uns per Telefon, E-Mail oder
Online-Formular kommunizieren
<br />
📓 Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse,
eingegebene Formulardaten. Mehr Details dazu finden Sie bei der
jeweils eingesetzten Kontaktart
<br />
🤝 Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern
usw.
<br />
📅 Speicherdauer: Dauer des Geschäftsfalls und gesetzlichen
Vorschriften
<br />
⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art.
6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit.f DSGVO
(Berechtigte Interessen)
</div>
<p>
Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder
Online-Formular kommunizieren, kann es zur Verarbeitung
personenbezogener Daten kommen.
</p>
<p>
In der Regel werden die Daten für die Dauer des Geschäftsvorgang
gespeichert bzw. so lange es das Gesetz vorschreibt. Die Daten
werden für die Abwicklung unserer Geschäftstätigkeiten verarbeitet.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Telefon
</h3>
<p>
Wenn Sie uns anrufen werden die Anrufdaten auf dem jeweiligen
Endgerät und beim eingesetzten Telekommunikationsanbieter
pseudonymisiert gespeichert. Außerdem können Daten wie Name und
Telefonnummer im Anschluß per E-Mail versendet und zur
Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht
sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben
erlauben.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
E-Mail
</h3>
<p>
Wenn Sie mit uns per E-Mail kommunizieren, werden Daten auf dem
jeweiligen Endgerät (Computer, Laptop, Smartphone,…) gespeichert und
es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten
werden gelöscht sobald der Geschäftsfall beendet wurde und es
gesetzliche Vorgaben erlauben.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Online Formulare
</h3>
<p>
Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten
auf unserem Webserver gespeichert. Die Daten werden gelöscht sobald
der Geschäftsfall beendet wurde und es gesetzliche Vorgaben
erlauben.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Rechtsgrundlagen
</h3>
<p>
Die Verarbeitung der Daten basiert auf den folgenden
Rechtsgrundlagen:
</p>
<ul class="adsimple-121739092">
<li class="adsimple-121739092">
    Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die
    Einwilligung Ihre Daten zu speichern und weiter für den
    Geschäftsfall betreffende Zwecke zu verwenden;
</li>
<li class="adsimple-121739092">
    Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit
    für die Erfüllung eines Vertrags mit Ihnen oder einem
    Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir müssen
    die Daten für vorvertragliche Tätigkeiten wie z. B. die
    Vorbereitung eines Angebots verarbeiten;
</li>
<li class="adsimple-121739092">
    Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen): Wir wollen
    Kundenanfragen und geschäftliche Kommunikation in einem
    professionellen Rahmen betreiben. Dazu sind gewisse technische
    Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und
    Mobilfunkbetreiber notwendig um die Kommunikation effizient
    betreiben zu können.
</li>
</ul>
<h2
id="webhosting"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
Webhosting
</h2>
<div style="margin-bottom: 10px;background: #fff;border: 1px solid #dfe1e5;border-radius: 8px;padding: 10px 10px 10px 15px">
<strong class="adsimple-121739092">
    Webhosting Zusammenfassung
</strong>
<br />
👥 Betroffene: Besucher der Website
<br />
📓 Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs,
verwendeter Browser und weitere Daten. Mehr Details dazu finden Sie
weiter unten bzw. beim jeweils eingesetzten Webhosting Provider.
<br />
🤝 Zweck: professionelles Hosting der Website und Absicherung des
Betriebs
<br />
📅 Speicherdauer: abhängig vom jeweiligen Provider, aber in der
Regel 2 Wochen
<br />
⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte
Interessen)
</div>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Was ist Webhosting?
</h3>
<p>
Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen
– auch personenbezogene Daten – automatisch erstellt und
gespeichert, so auch auf dieser Website. Diese Daten sollten
möglichst sparsam und nur mit Begründung verarbeitet werden. Mit
Website meinen wir übrigens die Gesamtheit aller Webseiten auf einer
Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller
letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum
Beispiel beispiel.de oder musterbeispiel.com.
</p>
<p>
Wenn Sie eine Website auf einem Bildschirm ansehen möchten,
verwenden Sie dafür ein Programm, welches sich Webbrowser nennt. Sie
kennen vermutlich einige Webbrowser beim Namen: Google Chrome,
Microsoft Edge, Mozilla Firefox und Apple Safari.
</p>
<p>
Dieser Webbrowser muss sich zu einem anderen Computer verbinden, wo
der Code der Website gespeichert ist: dem Webserver. Der Betrieb
eines Webservers ist eine komplizierte und aufwendige Aufgabe,
weswegen dies in der Regel von professionellen Anbietern, den
Providern, übernommen wird. Diese bieten Webhosting an und sorgen
damit für eine verlässliche und fehlerfreie Speicherung der Daten
von Websites.
</p>
<p>
Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer
(Desktop, Laptop, Smartphone) und während der Datenübertragung zu
und vom Webserver kann es zum Verarbeitung personenbezogener Daten
kommen. Einerseits speichert Ihr Computer Daten, andererseits muss
auch der Webserver Daten eine Zeit lang speichern um einen
ordentlichen Betrieb zu gewährleisten.
</p>
<p>Zur Veranschaulichung:</p>
<p>
<img
    role="img"
    src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg"
    alt="Browser und Webserver"
    width="100%"
/>
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Welche Daten werden verarbeitet?
</h3>
<p>
Auch während Sie unsere Website jetzt gerade besuchen, speichert
unser Webserver, das ist der Computer auf dem diese Webseite
gespeichert ist, in der Regel automatisch Daten wie
</p>
<ul class="adsimple-121739092">
<li class="adsimple-121739092">
    die komplette Internetadresse (URL) der aufgerufenen Webseite (z.
    B. https://www.beispielwebsite.de/beispielunterseite.html/)
</li>
<li class="adsimple-121739092">
    Browser und Browserversion (z. B. Chrome 87)
</li>
<li class="adsimple-121739092">
    das verwendete Betriebssystem (z. B. Windows 10)
</li>
<li class="adsimple-121739092">
    die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B.
    https://www.beispielquellsite.de/vondabinichgekommen.html/)
</li>
<li class="adsimple-121739092">
    den Hostname und die IP-Adresse des Geräts von welchem aus
    zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)
</li>
<li class="adsimple-121739092">Datum und Uhrzeit</li>
<li class="adsimple-121739092">
    in Dateien, den sogenannten Webserver-Logfiles.
</li>
</ul>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Warum verarbeiten wir personenbezogene Daten?
</h3>
<p>Die Zwecke der Datenverarbeitung sind:</p>
<ol>
<li class="adsimple-121739092">
    Professionelles Hosting der Website und Absicherung des Betriebs
</li>
<li class="adsimple-121739092">
    Aus Gründen der Betriebssicherheit und zur Erstellung von
    Zugriffsstatistiken
</li>
</ol>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Wie lange werden Daten gespeichert?
</h3>
<p>
In der Regel werden die oben genannten Daten zwei Wochen gespeichert
und danach automatisch gelöscht. Wir geben diese Daten nicht weiter,
können jedoch nicht ausschließen, dass diese Daten beim Vorliegen
von rechtswidrigem Verhalten von Behörden eingesehen werden.
</p>
<p>
<strong class="adsimple-121739092">Kurz gesagt:</strong> Ihr Besuch
wird durch unseren Provider (Firma, die unsere Website auf
speziellen Computern (Servern) laufen lässt), protokolliert, aber
wir geben Ihre Daten nicht ohne Zustimmung weiter!
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Rechtsgrundlage
</h3>
<p>
Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im
Rahmen des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO
(Wahrung der berechtigten Interessen), denn die Nutzung von
professionellem Hosting bei einem Provider ist notwendig um das
Unternehmen im Internet sicher und nutzerfreundlich präsentieren zu
können.
</p>
<h2
id="cookies"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
Cookies
</h2>
<div style="margin-bottom: 10px;background: #fff;border: 1px solid #dfe1e5;border-radius: 8px;padding: 10px 10px 10px 15px">
<strong class="adsimple-121739092">Cookies Zusammenfassung</strong>
<br />
👥 Betroffene: Besucher der Website
<br />
📓 Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie.
Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der
Software, die das Cookie setzt.
<br />
🤝 Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden
Sie weiter unten bzw. beim Hersteller der Software, die das Cookie
setzt.
<br />
📅 Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden
bis hin zu Jahren variieren
<br />
⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art.
6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)
</div>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Was sind Cookies?
</h3>
<p>
Unsere Webseite verwendet HTTP-Cookies, um nutzerspezifische Daten
zu speichern.
<br />
Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt
werden, damit Sie die folgende Datenschutzerklärung besser
verstehen.
</p>
<p>
Immer wenn Sie durch das Internet surfen, verwenden Sie einen
Browser. Bekannte Browser sind beispielsweise Chrome, Safari,
Firefox, Internet Explorer und Microsoft Edge. Die meisten Webseiten
speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt
man Cookies.
</p>
<p>
Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche
Helferlein. Fast alle Webseiten verwenden&nbsp;Cookies. Genauer
gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für
andere Anwendungsbereiche gibt. HTTP-Cookies&nbsp;sind kleine
Dateien, die von unserer Webseite auf Ihrem Computer gespeichert
werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner,
quasi dem “Hirn” Ihres Browsers, untergebracht. Ein Cookie besteht
aus einem Namen und einem Wert. Bei der Definition eines Cookies
müssen zusätzlich ein oder mehrere Attribute angegeben werden.
</p>
<p>
Cookies&nbsp;speichern gewisse Nutzerdaten von Ihnen, wie
beispielsweise Sprache oder persönliche Seiteneinstellungen. Wenn
Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die
„userbezogenen“ Informationen an unsere Seite zurück. Dank der
Cookies weiß unsere Webseite, wer Sie sind und bietet Ihnen die
Einstellung, die Sie gewohnt sind. In einigen Browsern hat
jedes&nbsp;Cookie&nbsp;eine eigene Datei, in anderen wie
beispielsweise Firefox sind alle&nbsp;Cookies&nbsp;in einer einzigen
Datei gespeichert.
</p>
<p>
Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem
Webbrowser wie z. B. Chrome und dem Webserver. Dabei fordert der
Webbrowser eine Website an und erhält vom Server ein Cookie zurück,
welches der Browser erneut verwendet, sobald eine andere Seite
angefordert wird.
</p>
<p>
<img
    role="img"
    src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg"
    alt="HTTP Cookie Interaktion zwischen Browser und Webserver"
    width="100%"
/>
</p>
<p>
Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies.
Erstanbieter-Cookies werden direkt von unserer Seite erstellt,
Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google
Analytics) erstellt.&nbsp;Jedes Cookie ist individuell zu bewerten,
da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines
Cookies variiert von ein paar Minuten bis hin zu ein paar
Jahren.&nbsp;Cookies sind keine Software-Programme und enthalten
keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch
nicht auf Informationen Ihres PCs zugreifen.
</p>
<p>So können zum Beispiel Cookie-Daten aussehen:</p>
<p>
<strong class="adsimple-121739092">Name:</strong> _ga
<br />
<strong class="adsimple-121739092">Wert:</strong>
&nbsp;GA1.2.1326744211.152121739092-9
<br />
<strong class="adsimple-121739092">Verwendungszweck:</strong> 
Unterscheidung der Webseitenbesucher
<br />
<strong class="adsimple-121739092">Ablaufdatum:</strong>&nbsp;nach 2
Jahren
</p>
<p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
<ul class="adsimple-121739092">
<li class="adsimple-121739092">Mindestens 4096 Bytes pro Cookie</li>
<li class="adsimple-121739092">Mindestens 50 Cookies pro Domain</li>
<li class="adsimple-121739092">
    Mindestens 3000 Cookies insgesamt
</li>
</ul>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Welche Arten von Cookies gibt es?
</h3>
<p>
Die Frage welche Cookies wir im Speziellen verwenden, hängt von den
verwendeten Diensten ab und wird in den folgenden Abschnitten der
Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf
die verschiedenen Arten von HTTP-Cookies eingehen.
</p>
<p>Man kann 4 Arten von Cookies unterscheiden:</p>
<p>
<strong class="adsimple-121739092">Unerlässliche Cookiesbr</strong>
Diese Cookies sind nötig, um grundlegende Funktionen der Webseite
sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein
User ein Produkt in den Warenkorb legt, dann auf anderen Seiten
weitersurft und später erst zur Kasse geht. Durch diese Cookies wird
der Warenkorb nicht gelöscht, selbst wenn der User sein
Browserfenster schließt.
</p>
<p>
<strong class="adsimple-121739092">
    Zweckmäßige Cookies
    <br />
</strong>
Diese Cookies sammeln Infos über das Userverhalten und ob der User
etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser
Cookies auch die Ladezeit und das Verhalten der Webseite bei
verschiedenen Browsern gemessen.
</p>
<p>
<strong class="adsimple-121739092">
    Zielorientierte Cookies
    <br />
</strong>
Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit.
Beispielsweise werden eingegebene Standorte, Schriftgrößen oder
Formulardaten gespeichert.
</p>
<p>
<strong class="adsimple-121739092">
    Werbe-Cookies
    <br />
</strong>
Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu
dem User individuell angepasste Werbung zu liefern. Das kann sehr
praktisch, aber auch sehr nervig sein.
</p>
<p>
Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite
gefragt, welche dieser Cookiearten Sie zulassen möchten. Und
natürlich wird diese Entscheidung auch in einem Cookie gespeichert.
</p>
<p>
Wenn Sie mehr über Cookies wissen möchten und technische
Dokumentationen nicht scheuen, empfehlen wir 
<a
    class="adsimple-121739092"
    href="https://tools.ietf.org/html/rfc6265"
    target="_blank"
    rel="nofollow noopener noreferrer"
>
    https://tools.ietf.org/html/rfc6265
</a>
, dem Request for Comments der Internet Engineering Task Force
(IETF) namens “HTTP State Management Mechanism”.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Welche Daten werden verarbeitet?
</h3>
<p>
Cookies sind kleine Gehilfen für eine Menge Aufgaben. Welche Daten
in Cookies gespeichert werden kann man leider nicht verallgemeinern,
aber wir werden Sie im Rahmen der folgenden Datenschutzerklärung
über die verarbeiteten bzw. gespeicherten Daten informieren.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Zweck der Verarbeitung über Cookies
</h3>
<p>
Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr
Details dazu finden Sie weiter unten bzw. beim Hersteller der
Software, die das Cookie setzt.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Speicherdauer von Cookies
</h3>
<p>
Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter
unter präzisiert. Manche Cookies werden nach weniger als 1h
gelöscht, andere können mehrere Jahre auf einem Computer gespeichert
bleiben.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Widerspruchsrecht – wie kann ich Cookies löschen?
</h3>
<p>
Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst.
Unabhängig von welchem Service oder welcher Webseite die Cookies
stammen, haben Sie immer die Möglichkeit&nbsp;Cookies zu löschen, zu
deaktivieren oder nur teilweise zuzulassen. Zum Beispiel können Sie
Cookies von Drittanbietern blockieren, aber alle anderen Cookies
zulassen.
</p>
<p>
Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser
gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder
löschen wollen, können Sie dies in Ihren Browser-Einstellungen
finden:
</p>
<p>
<a
    class="adsimple-121739092"
    href="https://support.google.com/chrome/answer/95647?tid=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    Chrome: Cookies in Chrome löschen, aktivieren und verwalten
</a>
</p>
<p>
<a
    class="adsimple-121739092"
    href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    Safari: Verwalten von Cookies und Websitedaten mit Safari
</a>
</p>
<p>
<a
    class="adsimple-121739092"
    href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
    Ihrem Computer abgelegt haben
</a>
</p>
<p>
<a
    class="adsimple-121739092"
    href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    Internet Explorer: Löschen und Verwalten von Cookies
</a>
</p>
<p>
<a
    class="adsimple-121739092"
    href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    Microsoft Edge: Löschen und Verwalten von Cookies
</a>
</p>
<p>
Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
entscheiden, ob Sie das Cookie erlauben oder nicht. Die
Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen
die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome”
oder “Cookies deaktivieren Chrome” im Falle eines Chrome Browsers.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Rechtsgrundlage
</h3>
<p>
Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist
festgehalten, dass das Speichern von Cookies eine 
<strong class="adsimple-121739092">Einwilligung</strong> (Artikel 6
Abs. 1 lit. a DSGVO) von Ihnen verlangt. Innerhalb der EU-Länder
gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese
Richtlinien. In Österreich erfolgte aber die Umsetzung dieser
Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes (TKG). In
Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht
umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie
weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).
</p>
<p>
Für unbedingt notwendige Cookies, wo keine Einwilligung vorliegt
bestehen 
<strong class="adsimple-121739092">berechtigte Interessen</strong> 
(Artikel 6 Abs. 1 lit. f DSGVO) die in den meisten Fällen
wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website
eine angenehme Benutzererfahrung bescheren und dafür sind Cookies
oft unbedingt notwendig.
</p>
<p>
In den folgenden Abschnitten werden Sie genauer über den Einsatz von
Cookies informiert, sofern eingesetzte Software Cookies verwendet.
</p>
<h2
id="web-analytics"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
Web Analytics
</h2>
<p>
Wir verwenden auf unserer Website Software zur Auswertung des
Verhaltens der Website-Besucher, kurz Web Analytics oder Web-Analyse
genannt. Dabei werden Daten gesammelt, die der jeweilige
Analytic-Tool Anbieter (auch Trackingtool genannt) speichert,
verwaltet und verarbeitet. Mit Hilfe der Daten werden Analysen über
das Nutzerverhalten auf unserer Website erstellt und uns als
Websitebetreiber zur Verfügung gestellt. Zusätzlich bieten die
meisten Tools verschiedene Testmöglichkeiten an. So können wir etwa
testen, welche Angebote oder Inhalte bei unseren Besuchern am besten
ankommen. Dafür zeigen wir Ihnen für einen begrenzten Zeitabschnitt
zwei verschiedene Angebote. Nach dem Test (sogenannter A/B-Test)
wissen wir, welches Produkt bzw. welcher Inhalt unsere
Websitebesucher interessanter finden. Für solche Testverfahren, wie
auch für andere Analytics-Verfahren, können auch Userprofile
erstellt werden und die Daten in Cookies gespeichert werden.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Warum betreiben wir Web-Analyse?
</h3>
<p>
Mit unserer Website haben wir ein klares Ziel vor Augen: wir wollen
für unsere Branche das besten Webangebot auf dem Markt liefern. Um
dieses Ziel zu erreichen wollen wir einerseits das beste und
interessanteste Angebot bieten und andererseits darauf achten, dass
Sie sich auf unserer Website rundum wohlfühlen. Mit Hilfe von
Webanalyse-Tools können wir das Verhalten unserer Websitebesucher
genauer unter die Lupe nehmen und dann entsprechend unser Webangebot
für Sie und uns verbessern. So können wir beispielsweise erkennen
wie alt unsere Besucher durchschnittlich sind, woher sie kommen,
wann unsere Website am meisten besucht wird oder welche Inhalte oder
Produkte besonders beliebt sind. All diese Informationen helfen uns
die Website zu optimieren und somit bestens an Ihre Bedürfnisse,
Interessen und Wünsche anzupassen.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Welche Daten werden vom Analytic-Tool gespeichert?
</h3>
<p>
Welche Daten genau gespeichert werden, hängt natürlich von den
verwendeten Analyse-Tools ab. Doch in der Regel wird zum Beispiel
gespeichert, welche Inhalte Sie auf unserer Website ansehen, auf
welche Buttons oder Links Sie klicken, wann Sie eine Seite aufrufen,
welchen Browser sie verwenden, mit welchem Gerät (PC, Tablet,
Smartphone usw.) Sie die Website besuchen oder welches
Computersystem Sie verwenden. Wenn Sie damit einverstanden waren,
dass auch Standortdaten erhoben werden dürfen, können auch diese
durch den Webanalyse-Tool-Anbieter verarbeitet werden.
</p>
<p>
Zudem wird auch Ihre IP-Adresse gespeichert. Gemäß der
Datenschutz-Grundverordnung (DSGVO) sind IP-Adressen
personenbezogene Daten. Ihre IP-Adresse wird allerdings in der Regel
pseudonymisiert (also in unkenntlicher und gekürzter Form)
gespeichert. Für den Zweck der Tests, der Webanalyse und der
Weboptimierung werden grundsätzlich keine direkten Daten, wie etwa
Ihr Name, Ihr Alter, Ihre Adresse oder Ihre E-Mail-Adresse
gespeichert. All diese Daten werden, sofern sie erhoben werden,
pseudonymisiert gespeichert. So können Sie als Person nicht
identifiziert werden.
</p>
<p>
Das folgende Beispiel zeigt schematisch die Funktionsweise von
Google Analytics als Beispiel für client-basiertes Webtracking mit
Java-Script-Code.
</p>
<p>
<img
    role="img"
    src="https://www.adsimple.at/wp-content/uploads/2021/04/google-analytics-dataflow.svg"
    alt="Schematischer Datenfluss bei Google Analytics"
    width="100%"
/>
</p>
<p>
Wie lange die jeweiligen Daten gespeichert werden, hängt immer vom
Anbieter ab. Manche Cookies speichern Daten nur für ein paar Minuten
bzw. bis Sie die Website wieder verlassen, andere Cookies können
Daten über mehrere Jahre speichern.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Die Rechtsgrundlage für die Verwendung von Analytic-Tools?
</h3>
<p>
Wenn Sie eingewilligt haben, dass Drittanbieter eingesetzt werden
dürfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung
diese Einwilligung.
</p>
<p>
Sie haben auch jederzeit das Recht und die Möglichkeit Ihre
Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu
widerrufen. Das funktioniert entweder über unser
Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum
Bespiel können Sie auch die Datenerfassung durch Cookies verhindern,
indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder
löschen.
</p>
<p>
Da bei Webseiten-Analytics-Tools Cookies zum Einsatz kommen,
empfehlen wir Ihnen auch unsere allgemeine Datenschutzerklärung über
Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert
und verarbeitet werden, sollten Sie die Datenschutzerklärungen der
jeweiligen Tools durchlesen.
</p>
<p>
Informationen zu speziellen Webseiten-Analytics-Tools, erhalten Sie
– sofern vorhanden – in den folgenden Abschnitten.
</p>
<div style="background: #efefef;border: 3px solid #ccc;padding: 10px">
<strong class="adsimple-121739092">Zusammenfassung</strong>
<br />
👥 Betroffene: Besucher der Website
<br />
📓 Verarbeitete Daten: je nach Analytics-Tool Anbieter
unterschiedlich, weitere Details auf Anfrage bzw. im Abschnitt zum
eingesetzten Anbieter
<br />
🤝 Grund für Datenverarbeitung: Auswertung der Besucherinformationen
zur Optimierung des Webangebots
</div>
<h2
id="social-media"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
Social Media
</h2>
<p>
Zusätzlich zu unserer Website sind wir auch in Social Media aktiv.
Dabei können Daten von Usern verarbeitet werden, damit wir gezielt
User, die sich für uns interessieren, über die sozialen Netzwerke
ansprechen können. Darüber hinaus können auch Elemente einer
Social-Media-Plattform direkt in unsere Website eingebettet sein.
Das ist etwa der Fall, wenn Sie einen sogenannten Social-Button auf
unserer Website anklicken und direkt zu unserem
Social-Media-Auftritt weitergeleitet werden.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Warum nutzen wir Social Media?
</h3>
<p>
Seit Jahren sind Social-Media-Plattformen der Ort, wo Menschen
online kommunizieren und in Kontakt treten. Mit unseren
Social-Media-Auftritten können wir unsere Produkte und
Dienstleistungen Interessenten näherbringen. Die auf unserer Website
eingebundenen Social-Media-Elemente helfen Ihnen, schnell und ohne
Komplikationen zu unseren Social-Media-Inhalten wechseln können.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Zu welchem Zweck werden Daten erhoben und gespeichert?
</h3>
<p>
Die Daten, die durch Ihre Nutzung eines Social-Media-Kanals
gespeichert und verarbeitet werden, dienen in erster Linie dazu,
Webanalysen durchführen zu können. Ziel dieser Analysen ist es,
genauere und personenbezogene Marketing- und Werbestrategien
entwickeln zu können. Abhängig von Ihrem Verhalten auf einer
Social-Media-Plattform, können mit Hilfe der ausgewerteten Daten,
passende Rückschlüsse auf Ihre Interessen getroffen werden und
sogenannte Userprofile erstellt werden. So ist es den Plattformen
auch möglich, Ihnen maßgeschneiderte Werbeanzeigen zu präsentieren.
Meistens werden für diesen Zweck Cookies in Ihrem Browser gesetzt,
die Daten zu Ihrem Nutzungsverhalten speichern.
</p>
<p>
Bitte beachten Sie, dass bei der Nutzung der
Social-Media-Plattformen oder unserer eingebauten Elemente auch
Daten von Ihnen außerhalb der Europäischen Union verarbeitet werden
können, da viele Social-Media-Kanäle, beispielsweise Facebook oder
Twitter, amerikanische Unternehmen sind. Dadurch können Sie
möglicherweise Ihre Rechte in Bezug auf Ihre personenbezogenen Daten
nicht mehr so leicht einfordern bzw. durchsetzen.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Welche Daten werden gespeichert?
</h3>
<p>
Welche Daten genau gespeichert und verarbeitet werden, hängt vom
jeweiligen Anbieter der Social-Media-Plattform ab. Aber für
gewöhnlich handelt es sich um Daten wie etwa Telefonnummern,
E-Mailadressen, Daten, die Sie in ein Kontaktformular eingeben,
Nutzerdaten wie zum Beispiel welche Buttons Sie klicken, wen Sie
liken oder wem folgen, wann Sie welche Seiten besucht haben,
Informationen zu Ihrem Gerät und Ihre IP-Adresse. Die meisten dieser
Daten werden in Cookies gespeichert. Speziell wenn Sie selbst ein
Profil bei dem besuchten Social-Media-Kanal haben und angemeldet
sind, können Daten mit Ihrem Profil verknüpft werden.
</p>
<p>
Alle Daten, die über eine Social-Media-Plattform erhoben werden,
werden auch auf den Servern der Anbieter gespeichert. Somit haben
auch nur die Anbieter Zugang zu den Daten und können Ihnen die
passenden Auskünfte geben bzw. Änderungen vornehmen.
</p>
<p>
Wenn Sie genau wissen wollen, welche Daten bei den
Social-Media-Anbietern gespeichert und verarbeitet werden und wie
sie der Datenverarbeitung widersprechen können, sollten Sie die
jeweilige Datenschutzerklärung des Unternehmens sorgfältig
durchlesen. Auch wenn Sie zur Datenspeicherung und Datenverarbeitung
Fragen haben oder entsprechende Rechte geltend machen wollen,
empfehlen wir Ihnen, sich direkt an den Anbieter wenden.
</p>
<p>
Informationen zu speziellen Social-Media-Plattformen erfahren Sie –
sofern vorhanden – in den folgenden Abschnitten.
</p>
<h2
id="facebook-datenschutzerklaerung"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
Facebook Datenschutzerklärung
</h2>
<p>
Wir verwenden auf unserer Webseite ausgewählte Tools von Facebook.
Facebook ist ein Social Media Network des Unternehmens Facebook
Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2
Ireland. Mithilfe dieser Tools können wir Ihnen und Menschen, die
sich für unsere Produkte und Dienstleistungen interessieren, das
bestmögliche Angebot bieten. Im Folgenden geben wir einen Überblick
über die verschiedenen Facebook Tools, welche Daten an Facebook
gesendet werden und wie Sie diese Daten löschen können.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Was sind Facebook-Tools?
</h3>
<p>
Neben vielen anderen Produkten bietet Facebook auch die sogenannten
“Facebook Business Tools” an.&nbsp;Das ist die offizielle
Bezeichnung von Facebook. Da der Begriff aber kaum bekannt ist,
haben wir uns dafür entschieden, sie lediglich Facebook-Tools zu
nennen. Darunter finden sich unter anderem:
</p>
<ul class="adsimple-121739092">
<li class="adsimple-121739092">Facebook-Pixel</li>
<li class="adsimple-121739092">
    soziale Plug-ins (wie z.B der „Gefällt mir“- oder „Teilen“-Button)
</li>
<li class="adsimple-121739092">Facebook Login</li>
<li class="adsimple-121739092">Account Kit</li>
<li class="adsimple-121739092">APIs (Programmierschnittstelle)</li>
<li class="adsimple-121739092">
    SDKs (Sammlung von Programmierwerkzeugen)
</li>
<li class="adsimple-121739092">Plattform-Integrationen</li>
<li class="adsimple-121739092">Plugins</li>
<li class="adsimple-121739092">Codes</li>
<li class="adsimple-121739092">Spezifikationen</li>
<li class="adsimple-121739092">Dokumentationen</li>
<li class="adsimple-121739092">
    Technologien und Dienstleistungen
</li>
</ul>
<p>
Durch diese Tools erweitert Facebook Dienstleistungen und hat die
Möglichkeit, Informationen über User-Aktivitäten außerhalb von
Facebook zu erhalten.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Warum verwenden wir Facebook-Tools auf unserer Website?
</h3>
<p>
Wir wollen unsere Dienstleistungen und Produkte nur Menschen zeigen,
die sich auch wirklich dafür interessieren. Mithilfe von
Werbeanzeigen (Facebook-Ads) können wir genau diese Menschen
erreichen. Damit den Usern passende Werbung gezeigt werden kann,
benötigt Facebook allerdings Informationen über die Wünsche und
Bedürfnisse der Menschen. So werden dem
Unternehmen&nbsp;Informationen über das Userverhalten (und
Kontaktdaten) auf unserer Webseite zur Verfügung gestellt. Dadurch
sammelt Facebook bessere User-Daten und kann interessierten Menschen
die passende Werbung über unsere Produkte bzw. Dienstleistungen
anzeigen. Die Tools ermöglichen somit maßgeschneiderte
Werbekampagnen auf Facebook.
</p>
<p>
Daten über Ihr Verhalten auf unserer Webseite nennt Facebook
„Event-Daten“. Diese werden auch für Messungs- und Analysedienste
verwendet. Facebook kann so in unserem Auftrag „Kampagnenberichte“
über die Wirkung unserer Werbekampagnen erstellen. Weiters bekommen
wir durch Analysen einen besseren Einblick, wie Sie unsere
Dienstleistungen, Webseite oder Produkte verwenden. Dadurch
optimieren wir mit einigen dieser Tools Ihre Nutzererfahrung auf
unserer Webseite. Beispielsweise können Sie mit den sozialen
Plug-ins Inhalte auf unserer Seite direkt auf Facebook teilen.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Welche Daten werden von Facebook-Tools gespeichert?
</h3>
<p>
Durch die Nutzung einzelner Facebook-Tools können personenbezogene
Daten (Kundendaten) an Facebook gesendet werden. Abhängig von den
benutzten Tools können Kundendaten wie Name, Adresse, Telefonnummer
und IP-Adresse versandt werden.
</p>
<p>
Facebook verwendet diese Informationen, um die Daten mit den Daten,
die es selbst von Ihnen hat (sofern Sie Facebook-Mitglied sind)
abzugleichen. Bevor Kundendaten an Facebook übermittelt werden,
erfolgt ein sogenanntes „Hashing“. Das bedeutet, dass ein beliebig
großer Datensatz in eine Zeichenkette transformiert wird. Dies dient
auch der Verschlüsselung von Daten.
</p>
<p>
Neben den Kontaktdaten werden auch „Event-Daten“ übermittelt. Unter
„Event-Daten“ sind jene Informationen gemeint, die wir über Sie auf
unserer Webseite erhalten. Zum Beispiel, welche Unterseiten Sie
besuchen oder welche Produkte Sie bei uns kaufen. Facebook teilt die
erhaltenen Informationen nicht mit Drittanbietern (wie
beispielsweise Werbetreibende), außer das Unternehmen hat eine
explizite Genehmigung oder ist rechtlich dazu verpflichtet.
„Event-Daten“ können auch mit Kontaktdaten verbunden werden. Dadurch
kann Facebook bessere personalisierte Werbung anbieten. Nach dem
bereits erwähnten Abgleichungsprozess löscht Facebook die
Kontaktdaten wieder.
</p>
<p>
Um Werbeanzeigen optimiert ausliefern zu können, verwendet Facebook
die Event-Daten nur, wenn diese mit anderen Daten (die auf andere
Weise von Facebook erfasst wurden) zusammengefasst wurden. Diese
Event-Daten nützt Facebook auch für Sicherheits-, Schutz-,
Entwicklungs- und Forschungszwecke. Viele dieser Daten werden über
Cookies zu Facebook übertragen. Cookies sind kleine Text-Dateien,
die zum Speichern von Daten bzw. Informationen in Browsern verwendet
werden. Je nach verwendeten Tools und abhängig davon, ob Sie
Facebook-Mitglied sind, werden unterschiedlich viele Cookies in
Ihrem Browser angelegt. In den Beschreibungen der einzelnen Facebook
Tools gehen wir näher auf einzelne Facebook-Cookies ein. Allgemeine
Informationen über die Verwendung von Facebook-Cookies erfahren Sie
auch auf 
<a
    class="adsimple-121739092"
    href="https://www.facebook.com/policies/cookies?tid=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    https://www.facebook.com/policies/cookies
</a>
.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Wie lange und wo werden die Daten gespeichert?
</h3>
<p>
Grundsätzlich speichert Facebook Daten bis sie nicht mehr für die
eigenen Dienste und Facebook-Produkte benötigt werden. Facebook hat
auf der ganzen Welt Server verteilt, wo seine Daten gespeichert
werden. Kundendaten werden allerdings, nachdem sie mit den eigenen
Userdaten abgeglichen wurden, innerhalb von 48 Stunden gelöscht.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Wie kann ich meine Daten löschen bzw. die Datenspeicherung
verhindern?
</h3>
<p>
Entsprechend der Datenschutz Grundverordnung haben Sie das Recht auf
Auskunft, Berichtigung, Übertragbarkeit und Löschung Ihrer Daten.
</p>
<p>
Eine komplette Löschung der Daten erfolgt nur, wenn Sie Ihr
Facebook-Konto vollständig löschen. Und so funktioniert das Löschen
Ihres Facebook-Kontos:
</p>
<p>1) Klicken Sie rechts bei Facebook auf Einstellungen.</p>
<p>
2) Anschließend klicken Sie in der linken Spalte auf „Deine
Facebook-Informationen“.
</p>
<p>3) Nun klicken Sie “Deaktivierung und Löschung”.</p>
<p>
4) Wählen Sie jetzt „Konto löschen“ und klicken Sie dann auf „Weiter
und Konto löschen“
</p>
<p>
5) Geben Sie nun Ihr Passwort ein, klicken Sie auf „Weiter“ und dann
auf „Konto löschen“
</p>
<p>
Die Speicherung der Daten, die Facebook über unsere Seite erhält,
erfolgt unter anderem über Cookies (z.B. bei sozialen Plugins). In
Ihrem Browser können Sie einzelne oder alle Cookies deaktivieren,
löschen oder verwalten. Je nach dem welchen Browser Sie verwenden,
funktioniert dies auf unterschiedliche Art und Weise. Die folgenden
Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:
</p>
<p>
<a
    class="adsimple-121739092"
    href="https://support.google.com/chrome/answer/95647?tid=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    Chrome: Cookies in Chrome löschen, aktivieren und verwalten
</a>
</p>
<p>
<a
    class="adsimple-121739092"
    href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    Safari: Verwalten von Cookies und Websitedaten mit Safari
</a>
</p>
<p>
<a
    class="adsimple-121739092"
    href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
    Ihrem Computer abgelegt haben
</a>
</p>
<p>
<a
    class="adsimple-121739092"
    href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    Internet Explorer: Löschen und Verwalten von Cookies
</a>
</p>
<p>
<a
    class="adsimple-121739092"
    href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    Microsoft Edge: Löschen und Verwalten von Cookies
</a>
</p>
<p>
Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
entscheiden, ob Sie es erlauben oder nicht.
</p>
<p>
Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von
Ihnen auch außerhalb der EU gespeichert und verarbeitet werden
können. Die meisten Drittstaaten (darunter auch die USA) gelten nach
derzeitigem europäischen Datenschutzrecht als nicht sicher. Daten an
unsichere Drittstaaten dürfen also nicht einfach übertragen, dort
gespeichert und verarbeitet werden, sofern es keine passenden
Garantien (wie etwa EU-Standardvertragsklauseln) zwischen uns und
dem außereuropäischen Dienstleister gibt.
</p>
<p>
Wir hoffen wir haben Ihnen die wichtigsten Informationen über die
Nutzung und Datenverarbeitung durch die Facebook-Tools
nähergebracht. Wenn Sie mehr darüber erfahren wollen, wie Facebook
Ihre Daten verwendet, empfehlen wir Ihnen die Datenrichtlinien auf 
<a
    class="adsimple-121739092"
    href="https://www.facebook.com/about/privacy/update"
    target="_blank"
    rel="noopener noreferrer"
>
    https://www.facebook.com/about/privacy/update
</a>
.
</p>
<h2
id="facebook-soziale-plug-ins-datenschutzerklaerung"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
Facebook Soziale Plug-ins Datenschutzerklärung
</h2>
<p>
Auf unserer Webseite sind sogenannte soziale Plug-ins des
Unternehmens Facebook Inc. eingebaut. Sie erkennen diese Buttons am
klassischen Facebook-Logo, wie dem „Gefällt mir“-Button (die Hand
mit erhobenem Daumen) oder an einer eindeutigen „Facebook
Plug-in“-Kennzeichnung. Ein soziales Plug-in ist ein kleiner Teil
von Facebook, der in unsere Seite integriert ist. Jedes Plug-in hat
eine eigene Funktion. Die am meisten verwendeten Funktionen sind die
bekannten “Gefällt mir”- und “Teilen”-Buttons.
</p>
<p>Folgende soziale Plug-ins werden von Facebook angeboten:</p>
<ul class="adsimple-121739092">
<li class="adsimple-121739092">“Speichern”-Button</li>
<li class="adsimple-121739092">
    “Gefällt mir”-Button, Teilen, Senden und Zitat
</li>
<li class="adsimple-121739092">Seiten-Plug-in</li>
<li class="adsimple-121739092">Kommentare</li>
<li class="adsimple-121739092">Messenger-Plug-in</li>
<li class="adsimple-121739092">
    Eingebettete Beiträge und Videoplayer
</li>
<li class="adsimple-121739092">Gruppen-Plug-in</li>
</ul>
<p>
Auf 
<a
    class="adsimple-121739092"
    href="https://developers.facebook.com/docs/plugins"
    target="_blank"
    rel="noopener noreferrer"
>
    https://developers.facebook.com/docs/plugins
</a> 
erhalten Sie nähere Informationen, wie die einzelnen Plug-ins
verwendet werden. Wir nützen die sozialen Plug-ins einerseits, um
Ihnen ein besseres Usererlebnis auf unserer Seite zu bieten,
andererseits weil Facebook dadurch unsere Werbeanzeigen optimieren
kann.
</p>
<p>
Sofern Sie ein Facebook-Konto haben oder 
<a
    class="adsimple-121739092"
    href="https://www.facebook.com/"
    target="_blank"
    rel="follow noopener noreferrer"
>
    facebook.com
</a> 
schon mal besucht haben, hat Facebook bereits mindestens ein Cookie
in Ihrem Browser gesetzt. In diesem Fall sendet Ihr Browser über
dieses Cookie Informationen an Facebook, sobald Sie unsere Seite
besuchen bzw. mit sozialen Plug-ins (z.B. dem „Gefällt mir“-Button)
interagieren.
</p>
<p>
Die erhaltenen Informationen werden innerhalb von 90 Tagen wieder
gelöscht bzw. anonymisiert. Laut Facebook gehören zu diesen Daten
Ihre IP-Adresse, welche Webseite Sie besucht haben, das Datum, die
Uhrzeit und weitere Informationen, die Ihren Browser betreffen.
</p>
<p>
Um zu verhindern, dass Facebook während Ihres Besuches auf unserer
Webseite viele Daten sammelt und mit den Facebook-Daten verbindet,
müssen Sie sich während des Webseitenbesuchs von Facebook abmelden
(ausloggen).
</p>
<p>
Falls Sie bei Facebook nicht angemeldet sind oder kein
Facebook-Konto besitzen, sendet Ihr Browser weniger Informationen an
Facebook, weil Sie weniger Facebook-Cookies haben. Dennoch können
Daten wie beispielsweise Ihre IP-Adresse oder welche Webseite Sie
besuchen an Facebook übertragen werden. Wir möchten noch
ausdrücklich darauf hinweisen, dass wir über die genauen Inhalte der
Daten nicht exakt Bescheid wissen. Wir versuchen aber Sie nach
unserem aktuellen Kenntnisstand so gut als möglich über die
Datenverarbeitung aufzuklären. Wie Facebook die Daten nutzt, können
Sie auch in den Datenrichtline des Unternehmens unter 
<a
    class="adsimple-121739092"
    href="https://www.facebook.com/about/privacy/update"
    target="_blank"
    rel="noopener noreferrer"
>
    https://www.facebook.com/about/privacy/update
</a> 
nachlesen.
</p>
<p>
Folgende Cookies werden in Ihrem Browser mindestens gesetzt, wenn
Sie eine Webseite mit sozialen Plug-ins von Facebook besuchen:
</p>
<p>
<strong class="adsimple-121739092">Name:</strong> dpr
<br />
<strong class="adsimple-121739092">Wert:</strong> keine Angabe
<br />
<strong class="adsimple-121739092">Verwendungszweck:</strong> Dieses
Cookie wird verwendet, damit die sozialen Plug-ins auf unserer
Webseite funktionieren.
<br />
<strong class="adsimple-121739092">Ablaufdatum:</strong> nach
Sitzungsende
</p>
<p>
<strong class="adsimple-121739092">Name:</strong> fr
<br />
<strong class="adsimple-121739092">Wert:</strong> 
0jieyh4121739092c2GnlufEJ9..Bde09j…1.0.Bde09j
<br />
<strong class="adsimple-121739092">Verwendungszweck:</strong>
&nbsp;Auch das Cookie ist nötig, dass die Plug-ins einwandfrei
funktionieren.
<br />
<strong class="adsimple-121739092">Ablaufdatum::</strong>&nbsp;nach
3 Monaten
</p>
<p>
<strong class="adsimple-121739092">Anmerkung:</strong> Diese Cookies
wurden nach einem Test gesetzt, auch wenn Sie nicht
Facebook-Mitglied sind.
</p>
<p>
Sofern Sie bei Facebook angemeldet sind, können Sie Ihre
Einstellungen für Werbeanzeigen unter 
<a
    class="adsimple-121739092"
    href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
    target="_blank"
    rel="noopener noreferrer"
>
    https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
</a>
&nbsp;selbst verändern. Falls Sie kein Facebook-User sind, können
Sie auf 
<a
    class="adsimple-121739092"
    href="http://www.youronlinechoices.com/de/praferenzmanagement/?tid=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    http://www.youronlinechoices.com/de/praferenzmanagement/
</a>
grundsätzlich Ihre nutzungsbasierte Online-Werbung verwalten. Dort
haben Sie die Möglichkeit, Anbieter zu deaktivieren bzw. zu
aktivieren.
</p>
<p>
Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen,
empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens
auf 
<a
    class="adsimple-121739092"
    href="https://www.facebook.com/policy.php?tip=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    https://www.facebook.com/policy.php
</a>
.
</p>
<h2
id="facebook-login-datenschutzerklaerung"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
Facebook Login Datenschutzerklärung
</h2>
<p>
Wir haben auf unserer Seite das praktische Facebook Login
integriert. So können Sie sich bei uns ganz einfach mit Ihrem
Facebook-Konto einloggen, ohne ein weiteres Benutzerkonto anlegen zu
müssen. Wenn Sie sich entscheiden, Ihre Registrierung über das
Facebook Login zu machen, werden Sie auf das Social Media Network
Facebook weitergeleitet. Dort erfolgt die Anmeldung über Ihre
Facebook Nutzerdaten. Durch dieses Login-Verfahren werden Daten über
Sie bzw. Ihr Userverhalten gespeichert und an Facebook übermittelt.
</p>
<p>
Um die Daten zu speichern, benutzt Facebook verschiedene Cookies. Im
Folgenden zeigen wir Ihnen die wichtigsten Cookies, die in Ihrem
Browser gesetzt werden bzw. schon bestehen, wenn Sie sich über das
Facebook Login auf unserer Seite anmelden:
</p>
<p>
<strong class="adsimple-121739092">Name:</strong> fr
<br />
<strong class="adsimple-121739092">Wert:</strong> 
0jieyh4c2GnlufEJ9..Bde09j…1.0.Bde09j
<br />
<strong class="adsimple-121739092">Verwendungszweck:</strong> Dieses
Cookie wird verwendet, damit das soziale Plugin auf unserer Webseite
bestmöglich funktioniert.
<br />
<strong class="adsimple-121739092">Ablaufdatum:</strong>&nbsp;nach 3
Monaten
</p>
<p>
<strong class="adsimple-121739092">Name:</strong> datr
<br />
<strong class="adsimple-121739092">Wert:</strong> 
4Jh7XUA2121739092SEmPsSfzCOO4JFFl
<br />
<strong class="adsimple-121739092">Verwendungszweck:</strong> 
Facebook setzt das “datr”-Cookie, wenn ein Webbrowser auf
facebook.com zugreift, und das Cookie hilft, Anmeldeaktivitäten zu
identifizieren und die Benutzer zu schützen.
<br />
<strong class="adsimple-121739092">Ablaufdatum:</strong>&nbsp;nach 2
Jahren
</p>
<p>
<strong class="adsimple-121739092">Name:</strong> _js_datr
<br />
<strong class="adsimple-121739092">Wert:</strong> deleted
<br />
<strong class="adsimple-121739092">Verwendungszweck:</strong> Dieses
Session-Cookie setzt Facebook zu Trackingzwecken, auch wenn Sie kein
Facebook-Konto haben oder ausgeloggt sind.
<br />
<strong class="adsimple-121739092">Ablaufdatum:</strong> nach
Sitzungsende
</p>
<p>
<strong class="adsimple-121739092">Anmerkung:</strong> Die
angeführten Cookies sind nur eine kleine Auswahl der Cookies, die
Facebook zur Verfügung stehen. Weitere Cookies sind beispielsweise _
fbp, sb oder wd. Eine vollständige Aufzählung ist nicht möglich, da
Facebook über eine Vielzahl an Cookies verfügt und diese variabel
einsetzt.
</p>
<p>
Der Facebook Login bietet Ihnen einerseits einen schnellen und
einfachen Registrierungsprozess, andererseits haben wir so die
Möglichkeit Daten mit Facebook zu teilen. Dadurch können wir unser
Angebot und unsere Werbeaktionen besser an Ihre Interessen und
Bedürfnisse anpassen. Daten, die wir auf diese Weise von Facebook
erhalten, sind öffentliche Daten wie
</p>
<ul class="adsimple-121739092">
<li class="adsimple-121739092">Ihr Facebook-Name</li>
<li class="adsimple-121739092">Ihr Profilbild</li>
<li class="adsimple-121739092">eine hinterlegte E-Mail-Adresse</li>
<li class="adsimple-121739092">Freundeslisten</li>
<li class="adsimple-121739092">
    Buttons-Angaben (z.B. „Gefällt mir“-Button)
</li>
<li class="adsimple-121739092">Geburtstagsdatum</li>
<li class="adsimple-121739092">Sprache</li>
<li class="adsimple-121739092">Wohnort</li>
</ul>
<p>
Im Gegenzug stellen wir Facebook Informationen über Ihre Aktivitäten
auf unserer Webseite bereit. Das sind unter anderem Informationen
über Ihr verwendetes Endgerät, welche Unterseiten Sie bei uns
besuchen oder welche Produkte Sie bei uns erworben haben.
</p>
<p>
Durch die Verwendung von Facebook Login willigen Sie der
Datenverarbeitung ein. Sie können diese Vereinbarung jederzeit
widerrufen. Wenn Sie mehr Informationen über die Datenverarbeitung
durch Facebook erfahren wollen, empfehlen wir Ihnen die
Facebook-Datenschutzerklärung unter 
<a
    class="adsimple-121739092"
    href="https://de-de.facebook.com/policy.php?tid=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    https://de-de.facebook.com/policy.php
</a>
.
</p>
<p>
Sofern Sie bei Facebook angemeldet sind, können Sie Ihre
Einstellungen für Werbeanzeigen unter 
<a
    class="adsimple-121739092"
    href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
    target="_blank"
    rel="noopener noreferrer"
>
    https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
</a>
&nbsp;selbst verändern.
</p>
<h2
id="google-maps-datenschutzerklaerung"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
Google Maps Datenschutzerklärung
</h2>
<p>
Wir benützen auf unserer Website Google Maps der Firma Google Inc.
Für den europäischen Raum ist das Unternehmen Google Ireland Limited
(Gordon House, Barrow Street Dublin 4, Irland) für alle
Google-Dienste verantwortlich. Mit Google Maps können wir Ihnen
Standorte besser zeigen und damit unser Service an Ihre Bedürfnisse
anpassen. Durch die Verwendung von Google Maps werden Daten an
Google übertragen und auf den Google-Servern gespeichert. Hier
wollen wir nun genauer darauf eingehen, was Google Maps ist, warum
wir diesen Google-Dienst in Anspruch nehmen, welche Daten
gespeichert werden und wie Sie dies unterbinden können.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Was ist Google Maps?
</h3>
<p>
Google Maps ist ein Internet-Kartendienst der Firma Google. Mit
Google Maps können Sie online über einen PC, ein Tablet oder eine
App genaue Standorte von Städten, Sehenswürdigkeiten, Unterkünften
oder Unternehmen suchen. Wenn Unternehmen auf Google My Business
vertreten sind, werden neben dem Standort noch weitere Informationen
über die Firma angezeigt. Um die Anfahrtsmöglichkeit anzuzeigen,
können Kartenausschnitte eines Standorts mittels HTML-Code in eine
Website eingebunden werden. Google Maps zeigt die Erdoberfläche als
Straßenkarte oder als Luft- bzw. Satellitenbild. Dank der Street
View Bilder und den hochwertigen Satellitenbildern sind sehr genaue
Darstellungen möglich.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Warum verwenden wir Google Maps auf unserer Website?
</h3>
<p>
All unsere Bemühungen auf dieser Seite verfolgen das Ziel, Ihnen
eine nützliche und sinnvolle Zeit auf unserer Webseite zu bieten.
Durch die Einbindung von Google Maps können wir Ihnen die
wichtigsten Informationen zu diversen Standorten liefern. Sie sehen
auf einen Blick wo wir unseren Firmensitz haben. Die Wegbeschreibung
zeigt Ihnen immer den besten bzw. schnellsten Weg zu uns. Sie können
den Anfahrtsweg für Routen mit dem Auto, mit öffentlichen
Verkehrsmitteln, zu Fuß oder mit dem Fahrrad abrufen. Für uns ist
die Bereitstellung von Google Maps Teil unseres Kundenservice.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Welche Daten werden von Google Maps gespeichert?
</h3>
<p>
Damit Google Maps ihren Dienst vollständig anbieten kann, muss das
Unternehmen Daten von Ihnen aufnehmen und speichern. Dazu zählen
unter anderem die eingegebenen Suchbegriffe, Ihre IP-Adresse und
auch die Breiten- bzw. Längenkoordinaten. Benutzen Sie die
Routenplaner-Funktion wird auch die eingegebene Startadresse
gespeichert. Diese Datenspeicherung passiert allerdings auf den
Webseiten von Google Maps. Wir können Sie darüber nur informieren,
aber keinen Einfluss nehmen. Da wir Google Maps in unsere Webseite
eingebunden haben, setzt Google mindestens ein Cookie (Name: NID) in
Ihrem Browser. Dieses Cookie speichert Daten über Ihr Userverhalten.
Google nutzt diese Daten in erster Linie, um eigene Dienste zu
optimieren und individuelle, personalisierte Werbung für Sie
bereitzustellen.
</p>
<p>
Folgendes Cookie wird aufgrund der Einbindung von Google Maps in
Ihrem Browser gesetzt:
</p>
<p>
<strong class="adsimple-121739092">Name:</strong> NID
<br />
<strong class="adsimple-121739092">Wert:</strong> 
188=h26c1Ktha7fCQTx8rXgLyATyITJ121739092-5
<br />
<strong class="adsimple-121739092">Verwendungszweck:</strong> NID
wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche
anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre am
häufigsten eingegebenen Suchanfragen oder Ihre frühere Interaktion
mit Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen.
Das Cookie enthält eine einzigartige ID, die Google benutzt, um Ihre
persönlichen Einstellungen für Werbezwecke zu sammeln.
<br />
<strong class="adsimple-121739092">Ablaufdatum:</strong> nach 6
Monaten
</p>
<p>
<strong class="adsimple-121739092">Anmerkung:</strong> Wir können
bei den Angaben der gespeicherten Daten keine Vollständigkeit
gewährleisten. Speziell bei der Verwendung von Cookies sind
Veränderungen nie auszuschließen. Um das Cookie NID zu
identifizieren, wurde eine eigene Testseite angelegt, wo
ausschließlich Google Maps eingebunden war.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Wie lange und wo werden die Daten gespeichert?
</h3>
<p>
Die Google-Server stehen in Rechenzentren auf der ganzen Welt. Die
meisten Server befinden sich allerdings in Amerika. Aus diesem Grund
werden Ihre Daten auch vermehrt in den USA gespeichert. Hier können
Sie genau nachlesen wo sich die Google-Rechenzentren befinden: 
<a
    class="adsimple-121739092"
    href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
    target="_blank"
    rel="noopener noreferrer"
>
    https://www.google.com/about/datacenters/inside/locations/?hl=de
</a>
</p>
<p>
Die Daten verteilt Google auf verschiedenen Datenträgern. Dadurch
sind die Daten schneller abrufbar und werden vor etwaigen
Manipulationsversuchen besser geschützt. Jedes Rechenzentrum hat
auch spezielle Notfallprogramme. Wenn es zum Beispiel Probleme bei
der Google-Hardware gibt oder eine Naturkatastrophe die Server lahm
legt, bleiben die Daten ziemlich sicher trotzdem geschützt.
</p>
<p>
Manche Daten speichert Google für einen festgelegten Zeitraum. Bei
anderen Daten bietet Google lediglich die Möglichkeit, diese manuell
zu löschen. Weiters anonymisiert das Unternehmen auch Informationen
(wie zum Beispiel Werbedaten) in Serverprotokollen, indem es einen
Teil der IP-Adresse und Cookie-Informationen nach 9 bzw.18 Monaten
löscht.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Wie kann ich meine Daten löschen bzw. die Datenspeicherung
verhindern?
</h3>
<p>
Mit der 2019 eingeführten automatischen Löschfunktion von Standort-
und Aktivitätsdaten werden Informationen zur Standortbestimmung und
Web-/App-Aktivität – abhängig von Ihrer Entscheidung – entweder 3
oder 18 Monate gespeichert und dann gelöscht. Zudem kann man diese
Daten über das Google-Konto auch jederzeit manuell aus dem Verlauf
löschen. Wenn Sie Ihre Standorterfassung vollständig verhindern
wollen, müssen Sie im Google-Konto die Rubrik „Web- und
App-Aktivität“ pausieren. Klicken Sie „Daten und Personalisierung“
und dann auf die Option „Aktivitätseinstellung“. Hier können Sie die
Aktivitäten ein- oder ausschalten.
</p>
<p>
In Ihrem Browser können Sie weiters auch einzelne Cookies
deaktivieren, löschen oder verwalten. Je nach dem welchen Browser
Sie verwenden, funktioniert dies immer etwas anders. Die folgenden
Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:
</p>
<p>
<a
    class="adsimple-121739092"
    href="https://support.google.com/chrome/answer/95647?tid=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    Chrome: Cookies in Chrome löschen, aktivieren und verwalten
</a>
</p>
<p>
<a
    class="adsimple-121739092"
    href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    Safari: Verwalten von Cookies und Websitedaten mit Safari
</a>
</p>
<p>
<a
    class="adsimple-121739092"
    href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
    Ihrem Computer abgelegt haben
</a>
</p>
<p>
<a
    class="adsimple-121739092"
    href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    Internet Explorer: Löschen und Verwalten von Cookies
</a>
</p>
<p>
<a
    class="adsimple-121739092"
    href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=121739092"
    target="_blank"
    rel="noopener noreferrer"
>
    Microsoft Edge: Löschen und Verwalten von Cookies
</a>
</p>
<p>
Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
entscheiden, ob Sie es erlauben oder nicht.
</p>
<p>
Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von
Ihnen auch außerhalb der EU gespeichert und verarbeitet werden
können. Die meisten Drittstaaten (darunter auch die USA) gelten nach
derzeitigem europäischen Datenschutzrecht als nicht sicher. Daten an
unsichere Drittstaaten dürfen also nicht einfach übertragen, dort
gespeichert und verarbeitet werden, sofern es keine passenden
Garantien (wie etwa EU-Standardvertragsklauseln) zwischen uns und
dem außereuropäischen Dienstleister gibt.
</p>
<p>
Wenn Sie mehr über die Datenverarbeitung von Google erfahren wollen,
empfehlen wir Ihnen die hauseigene Datenschutzerklärung des
Unternehmens unter 
<a
    class="adsimple-121739092"
    href="https://policies.google.com/privacy?hl=de"
    target="_blank"
    rel="noopener noreferrer"
>
    https://policies.google.com/privacy?hl=de
</a>
.
</p>
<h2
id="google-fonts-lokal-datenschutzerklaerung"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
Google Fonts Lokal Datenschutzerklärung
</h2>
<p>
Auf unserer Website nutzen wir Google Fonts der Firma Google Inc.
Für den europäischen Raum ist das Unternehmen Google Ireland Limited
(Gordon House, Barrow Street Dublin 4, Irland) verantwortlich. Wir
haben die Google-Schriftarten lokal, d.h. auf unserem Webserver –
nicht auf den Servern von Google – eingebunden. Dadurch gibt es
keine Verbindung zu Google-Servern und somit auch keine
Datenübertragung oder Speicherung.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Was sind Google Fonts?
</h3>
<p>
Früher nannte man Google Fonts auch Google Web Fonts. Dabei handelt
es sich um ein interaktives Verzeichnis mit über 800 Schriftarten,
die 
<a
    class="adsimple-121739092"
    href="https://de.wikipedia.org/wiki/Google_LLC?tid=121739092"
>
    Google
</a>
&nbsp;kostenlos bereitstellt. Mit Google Fonts könnte man Schriften
nutzen, ohne sie auf den eigenen Server hochzuladen. Doch um
diesbezüglich jede Informationsübertragung zu Google-Servern zu
unterbinden, haben wir die Schriftarten auf unseren Server
heruntergeladen. Auf diese Weise handeln wir datenschutzkonform und
senden keine Daten an Google Fonts weiter.
</p>
<p>
Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten
Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein
Meer an Schriftarten zugreifen und so das Optimum für unsere
Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden
Sie auf 
<a
    class="adsimple-121739092"
    href="https://developers.google.com/fonts/faq?tid=121739092"
>
    https://developers.google.com/fonts/faq?tid=121739092
</a>
.
</p>
<h2
id="font-awesome-datenschutzerklaerung"
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="24"
style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
data-lineheight="36px"
>
Font Awesome Datenschutzerklärung
</h2>
<p>
Wir verwenden auf unserer Website Font Awesome des amerikanischen
Unternehmens Fonticons (307 S. Main St., Suite 202, Bentonville, AR
72712, USA). Wenn Sie eine unserer Webseiten aufrufen, wird die
Web-Schriftart Font Awesome (im Speziellen Icons) über das Font
Awesome Content Delivery Netzwerk (CDN) geladen. So werden die Texte
bzw. Schriften und Icons auf jedem Endgerät passend angezeigt. In
dieser Datenschutzerklärung gehen wir näher auf die Datenspeicherung
und Datenverarbeitung durch diesen Service ein.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Was ist Font Awesome?
</h3>
<p>
Icons spielen für Websites eine immer wichtigere Rolle. Font Awesome
ist eine Web-Schriftart, die speziell für Webdesigner und
Webentwickler entwickelt wurde. Mit Font Awesome können etwa Icons
mit Hilfe der Stylesheet-Sprache CSS nach Belieben skaliert und
gefärbt werden. Sie ersetzen so alte Bild-Icons. Font Awesome CDN
ist der einfachste Weg die Icons oder Schriftarten auf Ihre Website
zu laden. Dafür mussten wir nur eine kleine Code-Zeile in unsere
Website einbinden.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Warum verwenden wir Font Awesome auf unserer Website?
</h3>
<p>
Durch Font Awesome können Inhalte auf unserer Website besser
aufbereitet werden. So können Sie sich auf unserer Website besser
orientieren und die Inhalte leichter erfassen. Mit den Icons kann
man sogar manchmal ganze Wörter ersetzen und Platz sparen. Da ist
besonders praktisch, wenn wir Inhalte speziell für Smartphones
optimieren.&nbsp; Diese Icons werden statt als Bild als HMTL-Code
eingefügt. Dadurch können wir die Icons mit CSS genauso bearbeiten,
wie wir wollen. Gleichzeitig verbessern wir mit Font Awesome auch
unsere Ladegeschwindigkeit, weil es sich nur um HTML-Elemente
handelt und nicht um Icon-Bilder. All diese Vorteile helfen uns, die
Website für Sie noch übersichtlicher, frischer und schneller zu
machen.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Welche Daten werden von Font Awesome gespeichert?
</h3>
<p>
Zum Laden von Icons und Symbolen wird das Font Awesome Content
Delivery Network (CDN) verwendet. CDNs sind Netzwerke von Servern,
die weltweit verteilt sind und es möglich machen, schnell Dateien
aus der Nähe zu laden. So werden auch, sobald Sie eine unserer
Seiten aufrufen, die entsprechenden Icons von Font Awesome
bereitgestellt.
</p>
<p>
Damit die Web-Schriftarten geladen werden können, muss Ihr Browser
eine Verbindung zu den Servern des Unternehmens Fonticons, Inc.
herstellen. Dabei wird Ihre IP-Adresse erkannt. Font Awesome sammelt
auch Daten darüber, welche Icon-Dateien wann heruntergeladen werden.
Weiters werden auch technische Daten wie etwa Ihre Browser-Version,
Bildschirmauflösung oder der Zeitpunkt der ausgerufenen Seite
übertragen.
</p>
<p>
Aus folgenden Gründen werden diese Daten gesammelt und gespeichert:
</p>
<ul class="adsimple-121739092">
<li class="adsimple-121739092">
    um Content Delivery Netzwerke zu optimieren
</li>
<li class="adsimple-121739092">
    um technische Fehler zu erkennen und zu beheben
</li>
<li class="adsimple-121739092">
    um CDNs vor Missbrauch und Angriffen zu schützen
</li>
<li class="adsimple-121739092">
    um Gebühren von Font Awesome Pro-Kunden berechnen zu können
</li>
<li class="adsimple-121739092">
    um die Beliebtheit von Icons zu erfahren
</li>
<li class="adsimple-121739092">
    um zu wissen, welchen Computer und welche Software Sie verwenden
</li>
</ul>
<p>
Falls Ihr Browser Web-Schriftarten nicht zulässt, wird automatisch
eine Standardschrift Ihres PCs verwendet. Nach derzeitigem Stand
unserer Erkenntnis werden keine Cookies gesetzt. Wir sind mit der
Datenschutzabteilung von Font Awesome in Kontakt und geben Ihnen
Bescheid, sobald wir näheres in Erfahrung bringen.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Wie lange und wo werden die Daten gespeichert?
</h3>
<p>
Font Awesome speichert Daten über die Nutzung des Content Delivery
Network auf Servern auch in den Vereinigten Staaten von Amerika. Die
CDN-Server befinden sich allerdings weltweit und speichern
Userdaten, wo Sie sich befinden. In identifizierbarer Form werden
die Daten in der Regel nur wenige Wochen gespeichert. Aggregierte
Statistiken über die Nutzung von den CDNs können auch länger
gespeichert werden. Personenbezogene Daten sind hier nicht
enthalten.
</p>
<h3
class="adsimple-121739092 fusion-responsive-typography-calculated"
data-fontsize="21"
style="--fontSize:21; line-height: 1.5; --minFontSize:21;"
data-lineheight="31.5px"
>
Wie kann ich meine Daten löschen bzw. die Datenspeicherung
verhindern?
</h3>
<p>
Font Awesome speichert nach aktuellem Stand unseres Wissens keine
personenbezogenen Daten über die Content Delivery Netzwerke. Wenn
Sie nicht wollen, dass Daten über die verwendeten Icons gespeichert
werden, können Sie leider unsere Website nicht besuchen. Wenn Ihr
Browser keine Web-Schriftarten erlaubt, werden auch keine Daten
übertragen oder gespeichert. In diesem Fall wird einfach die
Standard-Schrift Ihres Computers verwendet.
</p>
<p>
Wenn Sie mehr über Font Awesome und deren Umgang mit Daten erfahren
wollen, empfehlen wir Ihnen die Datenschutzerklärung unter 
<a
    class="adsimple-121739092"
    href="https://fontawesome.com/privacy"
>
    https://fontawesome.com/privacy
</a> 
und die Hilfeseite unter 
<a
    class="adsimple-121739092"
    href="https://fontawesome.com/help?tid=121739092"
>
    https://fontawesome.com/help
</a>
.
</p>
Alle Texte sind urheberrechtlich geschützt.
<p style="margin-top:15px">
Quelle: Erstellt mit dem 
<a
    href="https://www.adsimple.at/datenschutz-generator/"
    title="Datenschutz Generator von AdSimple"
>
    Datenschutz Generator
</a> 
von AdSimple
</p>
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Balance & Effizienz im Alltag" keywords={globals.keywords} />
    <ScBlackContainer>
      <ScMaxWidthContainer>
        <ScHeader3>Datenschutzerklärung</ScHeader3>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </ScMaxWidthContainer>
    </ScBlackContainer>
  </Layout>
);

export default IndexPage;
